<template>
  <v-row>
    <v-col cols="12" lg="6">
      <images-upload hide-images v-model="obModel" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Category } from "@planetadeleste/vue-mc-shopaholic";
import { Vue, Component, VModel } from "vue-property-decorator";

@Component
export default class CategoryImage extends Vue {
  @VModel({ type: Object, default: () => new Category() }) obModel!: Category;
}
</script>
